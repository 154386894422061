<template>
  <div class="brik white-bg">
    <router-link to="/home" data-cursor-hover ><img data-cursor-hover class="arrow-back" src="@/assets/arrowback.svg" alt=""></router-link>
<div class="wrapping-content">
    <section class="project-info">
    <!-- <figure class="program-icons">
            <img src="@/assets/aftereffects.svg" alt="">
            <img src="@/assets/pr.svg" alt="">
    </figure> -->
        <h1 class="l-indent2">The Federal Innovation Award</h1>
        <article>
                        <h3>Summary</h3>
<p>The first edition of the Federal Innovation Award, a competition for the best innovation initiative within the federal government, is over. No fewer than 48 innovation initiatives, submitted by 23 different federal government organizations, were submitted and reviewed by an independent jury.
Indiandribble created the visual identity, launched a social media campaign and produced the ceremony visuals for the award show.</p>
            <!-- <img src="@/assets/humons1.png" alt=""> -->
    <p style="font-size: 0.7em; clear: both;">© Concept & strategy developed by Indiandribble – All rights & copyright are reserved to Indiandribble.</p>
        </article>
        <article>
                <h3>My contribution</h3>
<p>I worked on making assets for the social media campaign and produced the projected visuals that were displayed in Plein Publiek's 360 degrees immersive room.</p>
        </article>
              <!-- <img src="@/assets/humons2.jpg" alt=""> -->
<section class="video-gallery" >
    <video style="padding-top: 2em;" class="float-1" autoplay muted loop>
        <source src="@/assets/fodlinkedin.mp4" type="video/mp4">
      </video>
    <div class="iframe-container">
  <iframe width="100%" height="800" src="https://www.youtube.com/embed/hgLgeGtWfX0?si=fE6iWPMfs1KTCFsv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
     
</section>
    </section>
</div>
    <section id="contact" class="contact">
      <div class="wrapping-content">        
        <a data-cursor-hover href="https://www.linkedin.com/in/fady-elabed-10603a183/" target="_blank">
        <svg version="1.1" id="linkedin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 23.3 22.4" style="enable-background:new 0 0 23.3 22.4;" xml:space="preserve">
        <path d="M5.1,22.1h-5v-15h5V22.1z M2.6,5.1C1.3,5.1,0.1,4,0.1,2.6s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C5.1,4,4,5.1,2.6,5.1z
          M23.1,22.1h-4.8v-7.3c0-1.7,0-4-2.5-4c-2.5,0-2.9,1.9-2.9,3.9v7.4H8.1v-15h4.6v2.1h0.1C13.5,8,15,6.8,17.4,6.8
          c4.9,0,5.8,3.1,5.8,7.2C23.1,13.9,23.1,22.1,23.1,22.1z"/>
        </svg>
        </a>
        <h2 class="l-indent">Let's have a chat <span>^.^</span></h2>
        <p>Interested in my work? Or want to talk about something we have in common? Reach out for a conversation.</p>
        <article>
        <a href="mailto:fady.elabed@hotmail.com" data-cursor-hover>fady.elabed@hotmail.com</a>
        <h3> or </h3>
        <a data-cursor-hover href="https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:cf08146f-dd4d-496d-851f-8cff66a81ac0" target="_blank">View my resume</a>
        </article>
      </div>
    </section>
    <cursor-fx>
    </cursor-fx>
  </div>
</template>

<script>
import { gsap } from 'gsap'



export default {
  mounted: function () {
    this.startAnimations()
  },
  methods: {
    startAnimations: function () {
      gsap.to('.reveal', {
        duration: 1.9,
        maxWidth: 1950,
        padding: 0,
        delay: 2,
        ease: 'power3.out'
      })
      gsap.from('header h1', {
        duration: 1.5,
        opacity: 0,
        delay: 2.4,
        y: 55,
        stagger: 0.2,
        ease: 'power3.out'
      })
    }
  }
}
</script>
