<template>
  <div class="drip white-bg">
    <router-link to="/home" data-cursor-hover ><img data-cursor-hover class="arrow-back" src="@/assets/arrowback.svg" alt=""></router-link>
<div class="wrapping-content">
    <section class="project-info">
    <!-- <figure class="program-icons">
            <img src="@/assets/aftereffects.svg" alt="">
            <img src="@/assets/pr.svg" alt="">
    </figure> -->
        <h1 class="l-indent2">Drip Festival: Celebrating Burger Culture</h1>
            <h3>Summary</h3>
        <article>
    <p>Introducing the hottest burger festival in Antwerp! Indiandribble were asked to put this tasty bun on the map and reach out to its target audience.</p>
            <p>Since the concept was still in the works, Indiandribble developed a teaser campaign featuring the two organisers: Bockie de Repper & Matthieu Terryn. This video production introduced Drip Festival to the world. Indiandribble asked people to sign up for our newsletter, in order to receive a 24-hour head start to purchase tickets. </p>  
              <p>Apart from creating a sales funnel by implementing clever mailing techniques, Indiandribble also launched various SoMe campaigns. Showing the wild postering content they made with Bockie and Matthieu, they were able to successfully tap into this unknown market. Resulting in numerous subscribers and the selling of 1200 tickets in just 3 days. Not only that, Indiandribble also produced all the festival assets for DRIP.</p>
              <h3>My contribution</h3>
              <p>I worked on the festival production, making the merchandise, banners, signs, menu's, etc. Also made static and animated content for the social media campaign.</p>
        </article>
<article>
    <!-- <img src="@/assets/" alt=""> -->
       </article>
        <img class="float-4" src="@/assets/dripbockie.jpeg" alt="">
        <img class="float-4" src="@/assets/drip.jpeg" alt="">
                              <p style="font-size: 0.7em; clear: both;">© Concept & strategy developed by Indiandribble – All rights & copyright are reserved to Indiandribble.</p>

    </section>
    <section class="gallery-case">

<video class="float-2" autoplay muted loop>
        <source src="@/assets/dripcelebs.mp4" type="video/mp4">
      </video>
      <video class="float-2" autoplay muted loop>
        <source src="@/assets/dripantwerp.mp4" type="video/mp4">
      </video>
<img class="float-4" src="@/assets/dripmerch.jpg" alt="">
      <video class="float-4" autoplay muted loop>
        <source src="@/assets/sneakers.mp4" type="video/mp4">
      </video>
            <video class="float-4" autoplay muted loop>
        <source src="@/assets/craftbeer.mp4" type="video/mp4">
      </video>
            <video class="float-4" autoplay muted loop>
        <source src="@/assets/neon.mp4" type="video/mp4">
      </video>
          </section>

</div>
    <section id="contact" class="contact">
      <div class="wrapping-content">        
        <a data-cursor-hover href="https://www.linkedin.com/in/fady-elabed-10603a183/" target="_blank">
        <svg version="1.1" id="linkedin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 23.3 22.4" style="enable-background:new 0 0 23.3 22.4;" xml:space="preserve">
        <path d="M5.1,22.1h-5v-15h5V22.1z M2.6,5.1C1.3,5.1,0.1,4,0.1,2.6s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C5.1,4,4,5.1,2.6,5.1z
          M23.1,22.1h-4.8v-7.3c0-1.7,0-4-2.5-4c-2.5,0-2.9,1.9-2.9,3.9v7.4H8.1v-15h4.6v2.1h0.1C13.5,8,15,6.8,17.4,6.8
          c4.9,0,5.8,3.1,5.8,7.2C23.1,13.9,23.1,22.1,23.1,22.1z"/>
        </svg>
        </a>
        <h2 class="l-indent">Let's have a chat <span>^.^</span></h2>
        <p>Interested in my work? Or want to talk about something we have in common? Reach out for a conversation.</p>
        <article>
        <a href="mailto:fady.elabed@hotmail.com" data-cursor-hover>fady.elabed@hotmail.com</a>
        <h3> or </h3>
        <a data-cursor-hover href="https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:cf08146f-dd4d-496d-851f-8cff66a81ac0" target="_blank">View my resume</a>
        </article>
      </div>
    </section>
    <cursor-fx>
    </cursor-fx>
  </div>
</template>

<script>
import { gsap } from 'gsap'



export default {
  mounted: function () {
    this.startAnimations()
  },
  methods: {
    startAnimations: function () {
      gsap.to('.reveal', {
        duration: 1.9,
        maxWidth: 1950,
        padding: 0,
        delay: 2,
        ease: 'power3.out'
      })
      gsap.from('header h1', {
        duration: 1.5,
        opacity: 0,
        delay: 2.4,
        y: 55,
        stagger: 0.2,
        ease: 'power3.out'
      })
    }
  }
}
</script>
