<template>
  <div class="pandeaming">
    <router-link to="/home" data-cursor-hover ><img data-cursor-hover class="arrow-back" src="@/assets/arrowback.svg" alt=""></router-link>
<div class="wrapping-content reveal">
<header>
        <video autoplay muted loop id="myVideo">
        <source src="@/assets/splashconcert.mp4" type="video/mp4">
      </video>
   <kinesis-container>
      <kinesis-element :strength="12"  type="translate" >
            <h1>how the</h1>
        </kinesis-element>
      <kinesis-element :strength="8"  type="depth" >
            <h1>pandemic</h1>
      </kinesis-element>
    <kinesis-element :strength="3"  type="rotate" >
            <h1>affected</h1>
    </kinesis-element>
    <kinesis-element :strength="10"  type="translate" >
            <h1>the music</h1>
    </kinesis-element>
    <kinesis-element :strength="10"  type="depth" >
            <h1>industry</h1>
    </kinesis-element>
    </kinesis-container>
</header>
    </div>
<section class="project-info">
  <div class="wrapping-content">
          <figure class="program-icons">
            <img src="@/assets/xd.svg" alt="">
            <img src="@/assets/vuelogo.svg" alt="">
        </figure>
    <!-- <img data-cursor-hover class="center-img logo-small" src="@/assets/logo1_blue.svg" alt=""> -->
      <h2 class="l-indent2">Pandeaming: A Final Work Project</h2>
  <article>
      <h3>Introduction</h3>
    <p>Pandeaming, the combination of pandemic and streaming. A Final Work project about how the current pandemic impacted the music industry and the ways they adapted/reacted. In collaboration with Erasmushogeschool Brussel. Isolation, probably one of the most important factors about this occurrence, is also reflected in the identity. Not only isolation, but also adaptation, distance and separation. Blending the digital aspect of the streaming side with our current circumstances.</p>
  </article>
  <article>
    <h3>Content</h3>
    <p>When visiting Pandeaming, you start of getting introduced with what's expected throughout this experience. First few chapters being on how the industry has been affected based on 4 aspects: sales - streaming - advertising - distribution. Followed by the virtual shows examples, where I highlight four different examples of innovative and unique digital events that have been organised throughout this pandemic as an answer to the restrictions.</p>
  </article>
  </div>
  <figure>
      <!-- <img src="@/assets/pan1.png" alt="">
      <img src="@/assets/pan5.png" alt=""> -->
      <img src="@/assets/pan6.png" alt="">
      <img src="@/assets/pan7.png" alt="">
  </figure>
  <img class="pan-trio" src="@/assets/pandeaming_logos.svg" alt="">
    <div class="wrapping-content">
    <article>
      <h3>Identity Branding</h3>
      <p>When building out my concept and developing the identity. I wanted to integrate pandemic and streaming throughout. The split in `typography represents the distance we’ve had to take from each other. The connected lines in the logo represent the isolation aspect of this ongoing pandemic. Which is a very big part of our daily lives currently.</p>
      <p>As you may know by now one of the most important things during this pandemic is that we have learned to adapt to the circumstances each time. That’s why I decided that I’ve had to integrate this into my identity as well. I’ve made 3 variations of the pandeaming identity that fill out their space each individually.</p>
      <a href="https://pandeaming.be/" class="" target="_blank" data-cursor-hover>Visit Pandeaming</a>
    </article>
        <video class="panvid" autoplay muted loop>
        <source src="@/assets/pandeaming.mp4" type="video/mp4">
      </video>
      <img class="mag" src="@/assets/pandeaming.gif" alt="">
    </div>

</section>    
<section id="contact" class="contact">
      <div class="wrapping-content">        
        <a data-cursor-hover href="https://www.linkedin.com/in/fady-elabed-10603a183/" target="_blank">
        <svg version="1.1" id="linkedin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 23.3 22.4" style="enable-background:new 0 0 23.3 22.4;" xml:space="preserve">
        <path d="M5.1,22.1h-5v-15h5V22.1z M2.6,5.1C1.3,5.1,0.1,4,0.1,2.6s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C5.1,4,4,5.1,2.6,5.1z
          M23.1,22.1h-4.8v-7.3c0-1.7,0-4-2.5-4c-2.5,0-2.9,1.9-2.9,3.9v7.4H8.1v-15h4.6v2.1h0.1C13.5,8,15,6.8,17.4,6.8
          c4.9,0,5.8,3.1,5.8,7.2C23.1,13.9,23.1,22.1,23.1,22.1z"/>
        </svg>
        </a>
        <h2 class="l-indent">Let's have a chat <span>^.^</span></h2>
        <p>Interested in my work? Or want to talk about something we have in common? Reach out for a conversation.</p>
        <article>
        <a href="mailto:fady.elabed@hotmail.com" data-cursor-hover>fady.elabed@hotmail.com</a>
        <h3> or </h3>
        <a data-cursor-hover href="https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:cf08146f-dd4d-496d-851f-8cff66a81ac0" target="_blank">View my resume</a>
        </article>
      </div>
    </section>
    <cursor-fx>
    </cursor-fx>
  </div>
</template>

<script>
import { gsap } from 'gsap'



export default {
  mounted: function () {
    this.startAnimations()
  },
  methods: {
    startAnimations: function () {
      gsap.to('.reveal', {
        duration: 1.9,
        maxWidth: 1950,
        padding: 0,
        delay: 2,
        ease: 'power3.out'
      })
      gsap.from('header h1', {
        duration: 1.5,
        opacity: 0,
        delay: 2.4,
        y: 55,
        stagger: 0.2,
        ease: 'power3.out'
      })
    }
  }
}
</script>
