<template>
  <div class="home" id="top">
        <!-- <smooth-scrollbar> -->
  <main>
    <nav id="nav">
      <div class="wrapping-content">
<router-link to="/" data-cursor-hover v-scroll-to="'#top'"><img class="logo" src="@/assets/logo_black.svg" alt="">
      </router-link>
      <!-- <p><router-link to="">Fady Elabed</router-link></p> -->
      <ul>
                <li><router-link to="/home" data-cursor-hover>Back</router-link></li>

      </ul>
        </div>
    </nav>

      <section id="highlight" class="highlighted bottomless p-top">
            <div class="wrapping-content">
              <h2>pandeaming / 2020 - 2021</h2>
              <figure>
              <img src="@/assets/xd.svg" alt="">
              <img src="@/assets/vuelogo.svg" alt="">
              </figure>
              <h1>The <span>impact</span> of covid on the music industry</h1>
              <article>
                <p>Pandeaming is a Final Work project on how the current pandemic affected the music industry. I developed an interactive / visual, audio-driven front-end web-experience using Vue.js. Pandeaming stands for the combination of ‘pandemic’ + ‘streaming’ and the identity was developed with aspects of our current situation in mind.  This was my last year’s Bachelor’s Project I worked on while studying at Erasmushogeschool Brussel.</p>
              <router-link to="/pandeaming" data-cursor-hover>Read more</router-link>
              </article>
            </div>
      <video autoplay muted loop>
        <source src="@/assets/pandeaming.mp4" type="video/mp4">
      </video>
      <img class="magazine hide" src="@/assets/pandeaming.gif" alt="">

    </section>
    <section class="highlighted yellow-bg">
            <div class="wrapping-content">
              <h2>dots & pix / 2020</h2>
              <figure>
              <img src="@/assets/xd.svg" alt="">
              <img class="angular" src="@/assets/angular.svg" alt="">
              </figure>
              <h1 class="l-indent">Pixels, dots, bits and more</h1>
              <article>
              <p>For our Front-end course project we had to design an innovative user-friendly website around a certain chosen theme. The website was for a fictional event about design and technology. Everyone had the same task but had to chose a different theme. I ended up chosing ‘glow’ as my theme and played around with light flares throughout the website.</p>
              <router-link to="/dotspix" data-cursor-hover>Read more</router-link>
              </article>
            </div>
      <video class="" autoplay muted loop>
        <source src="@/assets/dotspix.mp4" type="video/mp4">
      </video>
    </section>
    <section class="highlighted offset-top bottomless">
            <div class="wrapping-content">
              <h2>microflavours / 2017</h2>
              <figure>
              <img src="@/assets/photoshop.svg" alt="">
              <img src="@/assets/html.svg" alt="">
              <img src="@/assets/css.svg" alt="">
              </figure>
              <h1 class="l-indent">Brussels' most <span>impressive</span> indoor vertical farm</h1>
              <article>
              <p>Microflavours at the time (2017) was a startup company, growing microgreens in the heart of Brussels. The task was to create them a new redesigned website (preferably a one-pager) with the information provided of the current website they had back then. This assignment was given to me in my first year of college and was actually a team project we had to complete in under 3 weeks.</p>
              <router-link to="/microflavours" data-cursor-hover>Read more</router-link>
              </article>
            </div>
      <video class="" autoplay muted loop>
        <source src="@/assets/microflavours.mp4" type="video/mp4">
      </video>
      <img class="hide" src="@/assets/microflavours.jpeg" alt="">
    </section> 
     <section class="works" id="works">
      <div class="wrapping-content">
        <h2>Some other works that aren't highlighted, <span>but</span> still interesting nonetheless :)</h2>
        <figure class="work-gallery">
          <router-link to="/humons" data-cursor-hover>
          <article>
            <p>Fondation Mons: 'Humons'</p>
            <img src="@/assets/humons.svg" alt="">
          </article>
          </router-link>
          <router-link to="/botabib" data-cursor-hover>
          <article>
            <p>Botanical Garden Molenbeek</p>
            <img src="@/assets/botabib.svg" alt="">
          </article>
          </router-link>
          <a data-cursor-hover href="https://fadyelabed.be/kudasai/" target="_blank">
          <article>
            <p>Festival Kudasai</p>
            <img src="@/assets/kudasai.svg" alt="">
          </article>
          </a>
          <router-link to="/uitx" data-cursor-hover>
          <article>
            <p>BILL / UiTX: Animated visuals</p>
            <img src="@/assets/uitx.svg" alt="">
          </article>
          </router-link>
          <router-link to="/woonbox" data-cursor-hover>
          <article>
            <p>WOONBOX: Animated visuals</p>
            <img src="@/assets/woonbox.svg" alt="">
          </article>
          </router-link>
          <router-link to="/noeyez" data-cursor-hover>
          <article>
            <p>NOEYEZ: Animated visuals</p>
            <img src="@/assets/noeyez.svg" alt="">
          </article>
          </router-link>
          <a data-cursor-hover href="https://fadyelabed.be/tsuki/" target="_blank">
          <article>
            <p>Fictional Webshop Baka!</p>
            <img src="@/assets/baka.svg" alt="">
          </article>
          </a>
          <router-link to="/socup" data-cursor-hover>
          <article>  
            <p>Socup Media Rebrand</p>
            <img src="@/assets/socup.svg" alt="">
          </article>
          </router-link>
          <a data-cursor-hover href="https://fadyelabed.be/tsuki/" target="_blank">
          <article>
            <p>Tsuki: The Anime browser</p>
            <img src="@/assets/tsuki.svg" alt="">
          </article>
          </a>
        </figure>
      </div>
    </section>
      <section id="contact" class="contact">
      <div class="wrapping-content">
        <a data-cursor-hover href="https://www.linkedin.com/in/fady-elabed-10603a183/" target="_blank">
        <svg version="1.1" id="linkedin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 23.3 22.4" style="enable-background:new 0 0 23.3 22.4;" xml:space="preserve">
        <path d="M5.1,22.1h-5v-15h5V22.1z M2.6,5.1C1.3,5.1,0.1,4,0.1,2.6s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C5.1,4,4,5.1,2.6,5.1z
          M23.1,22.1h-4.8v-7.3c0-1.7,0-4-2.5-4c-2.5,0-2.9,1.9-2.9,3.9v7.4H8.1v-15h4.6v2.1h0.1C13.5,8,15,6.8,17.4,6.8
          c4.9,0,5.8,3.1,5.8,7.2C23.1,13.9,23.1,22.1,23.1,22.1z"/>
        </svg>
        </a>

        <h2 class="l-indent">Let's have a chat <span>^.^</span></h2>
        <p>Interested in my work? Or want to talk about something we have in common? Reach out for a conversation.</p>
        <article>
        <a href="mailto:fady.elabed@hotmail.com" data-cursor-hover>fady.elabed@hotmail.com</a>
        <!-- <h3>or </h3>
        <a data-cursor-hover href="https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:e8057aef-8977-456d-8759-d3a2d4d748e7" target="_blank">View my resume</a>
         -->
        </article>
      </div>
                      <p class="copyright">&copy; Designed & developed by Fady Elabed</p>        
    </section>
            <!-- <footer>
                <p>© designed & developed by fady elabed</p>
            </footer> -->
  </main>
              <cursor-fx>
    </cursor-fx>
        <!-- </smooth-scrollbar> -->
        <!-- <div id="nav">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
    </div> -->

  </div>
</template>

<script>





export default {
  mounted: function () {
  },
  methods: {

  }
}


</script>
