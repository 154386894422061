<template>
  <div class="brik white-bg">
    <router-link to="/home" data-cursor-hover ><img data-cursor-hover class="arrow-back" src="@/assets/arrowback.svg" alt=""></router-link>
<div class="wrapping-content">
    <section class="project-info">
    <!-- <figure class="program-icons">
            <img src="@/assets/aftereffects.svg" alt="">
            <img src="@/assets/pr.svg" alt="">
    </figure> -->
        <h1 class="l-indent2">BXL is: "Zijn wie je bent, worden wie je wil"</h1>
            <h3>Summary</h3>
        <article class="width-49" >
  <p>Indiandribble received a question from BRIK, a service desk for students. They asked how to convince students that Brussels is the ultimate city for their studies. Indiandribble brainstormed and found that nine out of ten people love Brussels because it allows them to be themselves. So, we created a video series showcasing the transformative power of Brussels. The videos follow five students whose lives changed when they came to Brussels. They also made an <a data-cursor-hover href="https://mijnbrussel.brik.be/" target="_blank"> interactive quiz</a> for students to discover their preferences and receive a personalized poster guide for their new room.</p>
       <p>"Zijn wie je bent, worden wie je wil" is the premise of this campaign which translates to "Be who you are, become who you want to be"</p>
              <h3>My contribution</h3>
              <p>I worked on animating endscreens (pancartes) for the campaign videos. Editing and creating video content for the social media campaign. <br></p>
      <p style="font-size: 0.7em; clear: both;">© Concept & strategy developed by Indiandribble – All rights & copyright are reserved to Indiandribble.</p>

        </article>
    <article style="margin: 0 !important">
        <video class="float-2" autoplay muted loop>
        <source src="@/assets/brikcombi.mp4" type="video/mp4">
      </video>
              <video class="float-2" autoplay muted loop>
        <source src="@/assets/brikcombo.mp4" type="video/mp4">
      </video>
</article>
              <!-- <img src="@/assets/humons2.jpg" alt=""> -->
<section class="video-gallery">
  <iframe width="100%" height="600" src="https://www.youtube.com/embed/GPN61W1Yxr0?si=f2jsoxLPKR_OP_LY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
<iframe width="100%" height="600" src="https://www.youtube.com/embed/t-3JxW3yfgY?si=mMd5kErK62eZT5mJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>        
     
</section>
    </section>
</div>
    <section id="contact" class="contact">
      <div class="wrapping-content">        
        <a data-cursor-hover href="https://www.linkedin.com/in/fady-elabed-10603a183/" target="_blank">
        <svg version="1.1" id="linkedin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 23.3 22.4" style="enable-background:new 0 0 23.3 22.4;" xml:space="preserve">
        <path d="M5.1,22.1h-5v-15h5V22.1z M2.6,5.1C1.3,5.1,0.1,4,0.1,2.6s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C5.1,4,4,5.1,2.6,5.1z
          M23.1,22.1h-4.8v-7.3c0-1.7,0-4-2.5-4c-2.5,0-2.9,1.9-2.9,3.9v7.4H8.1v-15h4.6v2.1h0.1C13.5,8,15,6.8,17.4,6.8
          c4.9,0,5.8,3.1,5.8,7.2C23.1,13.9,23.1,22.1,23.1,22.1z"/>
        </svg>
        </a>
        <h2 class="l-indent">Let's have a chat <span>^.^</span></h2>
        <p>Interested in my work? Or want to talk about something we have in common? Reach out for a conversation.</p>
        <article>
        <a href="mailto:fady.elabed@hotmail.com" data-cursor-hover>fady.elabed@hotmail.com</a>
        <h3> or </h3>
        <a data-cursor-hover href="https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:cf08146f-dd4d-496d-851f-8cff66a81ac0" target="_blank">View my resume</a>
        </article>
      </div>
    </section>
    <cursor-fx>
    </cursor-fx>
  </div>
</template>

<script>
import { gsap } from 'gsap'



export default {
  mounted: function () {
    this.startAnimations()
  },
  methods: {
    startAnimations: function () {
      gsap.to('.reveal', {
        duration: 1.9,
        maxWidth: 1950,
        padding: 0,
        delay: 2,
        ease: 'power3.out'
      })
      gsap.from('header h1', {
        duration: 1.5,
        opacity: 0,
        delay: 2.4,
        y: 55,
        stagger: 0.2,
        ease: 'power3.out'
      })
    }
  }
}
</script>
