<template>
  <div class="drip white-bg">
    <router-link to="/home" data-cursor-hover ><img data-cursor-hover class="arrow-back" src="@/assets/arrowback.svg" alt=""></router-link>
<div class="wrapping-content">
    <section class="project-info" style="padding-bottom: 0 !important;">
    <!-- <figure class="program-icons">
            <img src="@/assets/aftereffects.svg" alt="">
            <img src="@/assets/pr.svg" alt="">
    </figure> -->
        <h1 class="l-indent2">Le Pain Quotidien: All-Day Bakery</h1>
        <article>
                        <h3>Summary</h3>
<p>Le Pain Quotidien wanted to reposition themselves as an all-day bakery. To drive more dining room traffic and increase counter bread sales with 10%. Indiandribble tackled this task by creating a campaign that focuses on enjoying the small, best things in life.</p>
     <p style="font-size: 0.7em; clear: both;">© Concept & strategy developed by Indiandribble – All rights & copyright are reserved to Indiandribble.</p>

</article>
<article>
  <h3>My contribution</h3>
    <p>I worked on animating digital out of home assets for the campaign. These videos were spread out on different screens such as in Brussels Airport. I also worked on the social media campaign, making content for the awareness, traffic and conversion phases.</p>

 </article>
    </section>
    <section class="gallery-case">

      <video class="float-2" muted autoplay loop>
        <source src="@/assets/lpq_4x5.mp4" type="video/mp4">
      </video>
            <video class="float-2" muted autoplay loop>
        <source src="@/assets/lpq_dooh_4x5.mp4" type="video/mp4">
      </video>
                  <video class="float-1" controls>
        <source src="@/assets/lpq_16x9.mp4" type="video/mp4">
      </video>
          </section>

</div>
    <section id="contact" class="contact">
      <div class="wrapping-content">        
        <a data-cursor-hover href="https://www.linkedin.com/in/fady-elabed-10603a183/" target="_blank">
        <svg version="1.1" id="linkedin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 23.3 22.4" style="enable-background:new 0 0 23.3 22.4;" xml:space="preserve">
        <path d="M5.1,22.1h-5v-15h5V22.1z M2.6,5.1C1.3,5.1,0.1,4,0.1,2.6s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C5.1,4,4,5.1,2.6,5.1z
          M23.1,22.1h-4.8v-7.3c0-1.7,0-4-2.5-4c-2.5,0-2.9,1.9-2.9,3.9v7.4H8.1v-15h4.6v2.1h0.1C13.5,8,15,6.8,17.4,6.8
          c4.9,0,5.8,3.1,5.8,7.2C23.1,13.9,23.1,22.1,23.1,22.1z"/>
        </svg>
        </a>
        <h2 class="l-indent">Let's have a chat <span>^.^</span></h2>
        <p>Interested in my work? Or want to talk about something we have in common? Reach out for a conversation.</p>
        <article>
        <a href="mailto:fady.elabed@hotmail.com" data-cursor-hover>fady.elabed@hotmail.com</a>
        <h3> or </h3>
        <a data-cursor-hover href="https://documentcloud.adobe.com/link/review?uri=urn:aaid:scds:US:cf08146f-dd4d-496d-851f-8cff66a81ac0" target="_blank">View my resume</a>
        </article>
      </div>
    </section>
    <cursor-fx>
    </cursor-fx>
  </div>
</template>

<script>
import { gsap } from 'gsap'



export default {
  mounted: function () {
    this.startAnimations()
  },
  methods: {
    startAnimations: function () {
      gsap.to('.reveal', {
        duration: 1.9,
        maxWidth: 1950,
        padding: 0,
        delay: 2,
        ease: 'power3.out'
      })
      gsap.from('header h1', {
        duration: 1.5,
        opacity: 0,
        delay: 2.4,
        y: 55,
        stagger: 0.2,
        ease: 'power3.out'
      })
    }
  }
}
</script>
